import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Entite } from 'src/app/shared/models/entite';
import { base_url, endpoints } from '../../../../../../../assets/api.json';
import { ParamCommonService } from '../../common/param-common.service';
import { I_Entite } from '../../../../../../shared/interfaces/entite';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { rejects } from 'assert';
import { HttpClient } from '@angular/common/http';
import { api_res } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ParamEntiteService {


  public List_entite$ = new BehaviorSubject<I_Entite | []>( [] );
  public List_Categories$ = new BehaviorSubject<any | []>([])

  constructor(private paramCommon:ParamCommonService,
               private http: HttpClient) {
    this.emitListEntite();
    this.emitListCategorie();
  }


    /**
     * @description Emettre la liste des entités dans le behavior List_entite$
     */
    public emitListEntite(){
      this.getListEntite().then((res:any) => {
        this.List_entite$.next(res);
      })
    }

    /**
     * @description Emettre la liste des catégories entité
     */
    public emitListCategorie(){
      this.getAllCat().then((res:any) => {
        this.List_Categories$.next(res);
      })
    }

    /**
     * @description Récupérer la liste des Entités
     */
    getListEntite(): Promise<Array<I_Entite>>{

      const url = endpoints.getAllEntite;
      const list_entite = new Promise<Array<I_Entite>>((resolve, rejects) => {
          this.paramCommon.GET(url).subscribe((res:any) => {
              resolve(res.data)
        });

      })
      return list_entite;
    }

    /**
     * @description Récperer une entité
     *
     */
    getEntiteById(ent_code:string){

        const param:any = {
          ENT_CODE:ent_code
        };
        const url = endpoints.parametrages.autorites_contractantes.getEntiteByCode;
        const entite = new Promise((resolve, rejects) => {
          this.paramCommon.GET_BY_CODE(url, param).subscribe((res:any) => {
            resolve(res);
          })
        })
        return entite ;

    }

    updateEntiteByCode(data:string){

      const url = endpoints.parametrages.autorites_contractantes.updateEntite;
      const Response = new Promise((resolve, reject) => {
          this.paramCommon.PUT(url, data).subscribe((res:any) => {
            resolve(res);
          })
      })
      return Response;

    }

    insertEntite(data:string){

      const url = endpoints.parametrages.autorites_contractantes.insertEntite;
      const Response = new Promise((resolve, reject) => {

        this.paramCommon.POST(url, data).subscribe((res:any) => {
            resolve(res);
        })
      })
      return Response;

    }

    getAllCat(){

      const url = endpoints.parametrages.autorites_contractantes.getAllCategorieEntite;
      const categories = new Promise((resolve, rejects) => {

          this.paramCommon.GET(url).subscribe((res:any) => {
              resolve(res);
          })

      })
      return categories;

    }

    changeStatusEntite(data:any){

      const url = endpoints.parametrages.autorites_contractantes.active_desactive_Entite;
      const status = new Promise((resolve, reject) => {

        this.paramCommon.PUT(url, data).subscribe((res:any) => {
          resolve(res)
        })

      })
      return status;

    }

}
