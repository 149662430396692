import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { base_url_local,base_url, endpoints } from '../../../../../../assets/api.json';
import { I_Param_Response } from '../../config/utilsInterface';
import { api_res } from './../../../../../shared/interfaces/api';

@Injectable({
  providedIn: 'root'
})
export class ParamCommonService {


  constructor( private http: HttpClient, public toastr: ToastrService) { }

        /**
         * @returns Header option
         */
        setOption(){
          const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            }),
          };
          return options;
        }

        /**
         * @description Requette GET
         */
        GET(url:string):Observable<api_res>{

          const api_url = base_url + url;
          return this.http.get<api_res>(api_url, this.setOption());

        }

        /**
         *
         * @description  Requette GET avec paramètre
         * @param Identification
         */
        GET_BY_CODE(url:string, param:any){

          const api_url = base_url + url;
          return this.http.get<api_res>(api_url, {params:param} )

        }

        /**
         * @description Requette POST
         */
        POST(url:string, data:any){

          const api_url = base_url + url;
          return this.http.post<api_res>(api_url, data, this.setOption());

        }

        /**
         * @description Requette PUT
         */
        PUT(url:string, data:any): Observable<api_res> {

          const api_url = base_url + url;
          return this.http.put<api_res>(api_url, data, this.setOption());

        }

        /**
          * @description cette fonction sera appelé pour récupérer la response de l'API
          *               et d'exécuter une fonction en retour
        */
        getResponse(res:I_Param_Response, callBack: () => any ){

          if(res.status){

            callBack();
            this.toastr.success(res.message, 'Succès');

          }else{

            this.toastr.success(res.message, 'Erreur');

          }

      }


}
