import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { endpoints, base_url} from 'src/assets/api.json';
import { EplCommonService } from '../common/epl-common.service';
import { Lot } from 'src/app/modules/e-dossier/interfaces';
import { api_res } from 'src/app/shared/interfaces';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/shared/services/user/user.service';
import { AppToolsService } from 'src/app/shared/services/tools/app-tools.service';

@Injectable({
  providedIn: 'root'
})
export class  EplRegroupementLotsService {
  DMD_ANNEE:string
  DateNow = new Date();
  private  ListeLotOfPPM_DTL:Array<any> = [];

  public Lot$ = new BehaviorSubject([] as any[]);
  public LotPpmDtlList = new BehaviorSubject([] as any[]);

  public besoin_encours$ = new BehaviorSubject("");
  public code_besoin = new BehaviorSubject("");

  constructor(
    private http: HttpClient,
    private commom$: EplCommonService,
    public datepipe:DatePipe,
    public userService: UserService,
    private appToolService: AppToolsService,
	) {

  }

  GetCodeBesoinEnCours(data:any){
    const url = base_url + endpoints.ePlanification.determination.listeByFilter
    return this.http.get(url, {params:{ANNEE: data}})
   //  this.code_besoin.next(this.besoin_encours$.value)
  }

  public emitLotPpmDtl$(user:any, dmd_annee:any){

      this.GetLotsNotAssigned(user, dmd_annee).subscribe(res=>{
        this.Lot$.next([])
        if(res.status == 200){
          this.Lot$.next(res.data)
          this.appToolService.hideLoader()
        }else{
          this.Lot$.next([])
          this.appToolService.hideLoader()
        }
      })

  }

  GetAllModePassation(): Observable<any> {

    const url = base_url + endpoints.getAllModePassation
    return this.http.get(url);

  }

  GetAllFinancement(): Observable<any> {

    const url = base_url + endpoints.ePlanification.determination.getAllFinancement
    return this.http.get(url);

  }

  GetAllTypeMarcher(): Observable<any> {

    const url = base_url + endpoints.getAllTypeMarche
    return this.http.get(url);

  }

  GetLotByBesoinCode(code:string):Observable<any>{

    const url = base_url + endpoints.ePlanification.regroupementBesoin.getalldata
    return this.http.get(url, { params : { BESOIN_CODE: code }});

  }

  GetLotsNotAssigned(Ent_code:any, annee:any):Observable<any>{

    const params:any =  {
      ENT_CODE:Ent_code,
      DMD_ANNEE:annee
    }
    const url = base_url + endpoints.ePlanification.regroupementLots.getLotsNotAssigned;
    return this.http.get(url, {params:params});

  }


  GetLotByPpmDtlCode(code:string):Observable<any>{

    const url = base_url + endpoints.ePlanification.regroupementLots.getLotByppdtl
    return this.http.get<any>(url, {params:{PPM_DTL_CODE: code}});

  }

  UpdatePpmDtl(data:any):Observable<any>{

    const url = base_url + endpoints.ePlanification.regroupementLots.updatePpmDtlByCode;
    return this.http.put<any>(url, data);

  }
	getModeATTRModePassation(ppm_dtl_code: string | null , type: string | null , montant: any | null , ent_code: string | null){

		return this.http.post(base_url + endpoints.ePlanification.regroupementLots.getModePassation,
      {
				PPM_DTL_CODE:ppm_dtl_code,
				TYPE: type,
				MONTANT:montant,
				ENT_CAT_CODE:ent_code
			})

	}

  // AddLotInPpmDtl(data:any){
  //   this.ListeLotOfPPM_DTL = data;
  //   this.emitLotPpmDtl(	this.DMD_ANNEE );
  // }
}
