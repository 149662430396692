import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I_Cao } from 'src/app/shared/interfaces/cao';
import { Pagination, Response } from '../../interfaces';
import { base_url, endpoints } from 'src/assets/api.json';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropositionMembre, PropositionMembreListeParams } from '../../interfaces/proposition-membre';
import { api_res } from 'src/app/shared/interfaces';

@Injectable()
export class PropositionMembreService {
  public caoDisponibles$ = new BehaviorSubject<I_Cao[]>([]);

  constructor(
    public http: HttpClient
  ) {

  }

  getAllCaoAvailable(code_dossier: string) {
    return this.http.get<Response<I_Cao[]>>(`${base_url}${endpoints.e_evaluation.get_all_cao_disponible}`, {
      params: {
        dossier_code: code_dossier
      }
    });
  }

  getAllPropositionMembre(queries: PropositionMembreListeParams) {
    return this.http.get<Response<Pagination<PropositionMembre>>>(`${base_url}${endpoints.e_evaluation.get_all_proposition_membre}`, {
      params: JSON.parse(JSON.stringify(queries)) // remove all undefined properties
    });
  }

  addPropositionMembre(info: any) {
    const params = new FormData();
    params.append('observation', info.autreValue);
    params.append('dossier_code', info.dossier_code);
    for (const cao_code in info.propositions) {
      params.append(`cao[${cao_code}]`, info.propositions[cao_code]);
    }
    return this.http.post<api_res>(`${base_url}${endpoints.e_evaluation.add_proposition_membre}`, params);
  }

  deleteProposition(info: any) {
    const params = new URLSearchParams();
    params.append('dossier_code', info.dossier_code);
    for (const cao_code in info.deleteds) {
      params.append(`cao[${cao_code}]`, info.deleteds[cao_code]);
    }
    return this.http.get<api_res>(
      `${base_url}${endpoints.e_evaluation.delete_proposition_membre}?${params.toString()}`);
  }

  changeEtatPropMbre(ETAT: string, CAO_CODE: string, DOSSIER_CODE: string): Observable<api_res> {
    return this.http.put<api_res>(base_url + endpoints.e_evaluation.changeEtatPropMbre, { ETAT, CAO_CODE, DOSSIER_CODE });
  }
}
