import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Direction } from 'src/app/shared/models/direction';
import { base_url, endpoints } from '../../../../../../../assets/api.json';
import { ParamCommonService } from '../../common/param-common.service';
import { I_Direction } from '../../../../../../shared/interfaces/direction';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { rejects } from 'assert';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParamDirectionService {


  public List_direction$ = new BehaviorSubject<I_Direction | []>( [] );

  constructor(private paramCommon:ParamCommonService) {
    this.emitListDirection();
  }


    /**
     * @description Emitter la liste des directions dans le behavior List_direction$
     */
    public emitListDirection(){
      this.getListDirection().then((res:any) => {
        this.List_direction$.next(res);
      })
    }

		changeStatusDirection(data:any){
				const url = endpoints.parametrages.autorites_contractantes.active_desactive_Direction;
      	const status = new Promise((resolve, reject) => {
					this.paramCommon.PUT(url, data).subscribe((res:any) => {
						resolve(res)
					})
      	})
      return status;
		}

    /**
     * @description Récupérer la liste des directions
     */
     getListDirection(): Promise<Array<I_Direction>>{

      const url = endpoints.getAllDirection;
      const list_dir = new Promise<Array<I_Direction>>((resolve, rejects) => {
          this.paramCommon.GET(url).subscribe((res:any) => {
              resolve(res.data)
        });

      })
      return list_dir;
    }

    /**
     * @description Récperer une direction
     */
    getDirectionById(ent_code:string){

        const param:any = {
          cdDirection:ent_code
        };
        const url = endpoints.parametrages.autorites_contractantes.getDirectionByCode;
        const entite = new Promise((resolve, rejects) => {
          this.paramCommon.GET_BY_CODE(url, param).subscribe((res:any) => {
            resolve(res);
          })
        })
        return entite ;

    }

		getListCodeMere(){
				const url = endpoints.parametrages.autorites_contractantes.getListCodeMere;
				const codeMere = new Promise((resolve, rejects) => {
					this.paramCommon.GET(url).subscribe((res:any) => {
						resolve(res);
					})
				})
				return codeMere ;
		}

		CreateDirection(data:any) {
			let new_direction = {
				DATA_DIRECTION: data
			}
			// console.log(new_direction);
			const url = endpoints.parametrages.autorites_contractantes.create_direction
			const retour = new Promise((resolve, rejects) => {
				this.paramCommon.POST(url, data).subscribe((res:any) => {
					resolve(res);
				})
			})
			return retour
		}
		UpdateDirection(data:any) {
			const url = endpoints.parametrages.autorites_contractantes.update_direction
			const retour = new Promise((resolve, rejects) => {
				this.paramCommon.PUT(url, data).subscribe((res:any) => {
					resolve(res);
				})
			})
			return retour
		}
}
